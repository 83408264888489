import { stripRichTextWrapperTag } from '~/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import forms from '~/i18n/messages/fr-fr.forms.json';
import mergeI18n from '~/utils/merge-i18n';

const dataI18n = Object.assign({}, forms);

export const getCalculatorForm = (
  { calculatorFormTitle, calculatorFormIntro, calculatorFormFooter },
  i18n
) => {
  return {
    title: stripRichTextWrapperTag(calculatorFormTitle),
    intro: stripRichTextWrapperTag(calculatorFormIntro),
    sections: [
      {
        id: 'section-loan',
        inputs: [
          {
            name: 'euro',
            value: '',
            validator: 'montantEmprunt',
            required: true,
            field: {
              id: 'euro',
              label: i18n.t('forms.entries.montantemprunt.label'),
              inputmode: 'numeric',
              unit: '€',
              type: 'text',
            },
            messages: {
              required: i18n.t(
                'forms.entries.montantemprunt.messages.required'
              ),
              invalid: i18n.t('forms.entries.montantemprunt.messages.invalid'),
            },
          },
          {
            name: 'percentage',
            value: '',
            validator: 'tauxPret',
            required: true,
            field: {
              id: 'percentage',
              label: i18n.t('forms.entries.tauxpret.label'),
              unit: '%',
              inputmode: 'decimal',
              type: 'number',
              min: 0,
              max: 20,
              step: '0.01',
            },
            messages: {
              required: i18n.t('forms.entries.tauxpret.messages.required'),
              invalid: i18n.t('forms.entries.tauxpret.messages.invalid'),
            },
          },
          {
            name: 'duration',
            value: '',
            required: true,
            validator: 'dureePret',
            field: {
              id: 'duree',
              label: i18n.t('forms.entries.dureepret.label'),
              unit: 'mois',
              inputmode: 'numeric',
              type: 'text',
              min: 0,
            },
            messages: {
              required: i18n.t('forms.entries.dureepret.messages.required'),
              invalid: i18n.t('forms.entries.dureepret.messages.invalid'),
            },
          },
        ],
      },
      {
        id: 'section-monthly-payment',
        intro: 'Date de la première mensualité',
        inputs: [
          {
            name: 'associated',
            field: {
              type: 'associated',
              inputs: [
                {
                  name: 'month',
                  value: '',
                  required: true,
                  field: {
                    id: 'month',
                    label: 'Mois',
                    type: 'select',
                    options: Array.from({ length: 12 }, (_, index) => ({
                      value: (index + 1).toString(),
                      label: new Date(Date.UTC(2022, index)).toLocaleDateString(
                        i18n.locale,
                        { month: 'long' }
                      ),
                    })),
                  },
                  messages: {
                    required: i18n.t(
                      'forms.entries.datePremiereMensualite.mois.messages.required'
                    ),
                    invalid: i18n.t(
                      'forms.entries.datePremiereMensualite.mois.messages.invalid'
                    ),
                  },
                },
                {
                  name: 'year',
                  value: '',
                  required: true,
                  field: {
                    id: 'year',
                    label: 'Année',
                    type: 'select',
                    options: Array.from({ length: 20 }, (_, index) => ({
                      value: (new Date().getFullYear() - index).toString(),
                      label: (new Date().getFullYear() - index).toString(),
                    })),
                  },
                  messages: {
                    required: i18n.t(
                      'forms.entries.datePremiereMensualite.annee.messages.required'
                    ),
                    invalid: i18n.t(
                      'forms.entries.datePremiereMensualite.annee.messages.invalid'
                    ),
                  },
                },
              ],
            },
          },
        ],
      },
    ],
    footer: stripRichTextWrapperTag(calculatorFormFooter),
  };
};

export const getCalculatorCard = (primary, $enhancedLinkSerializer) => {
  const {
    calculatorCardIcon,
    calculatorCardTitle,
    calculatorCardDescription,
    calculatorCard_ctaLabel,
    calculatorCardCtaLink,
  } = primary || {};

  return {
    icon: imageAdapter(calculatorCardIcon),
    title: stripRichTextWrapperTag(calculatorCardTitle),
    description: stripRichTextWrapperTag(calculatorCardDescription),
    cta: calculatorCardCtaLink &&
      calculatorCard_ctaLabel && {
        label: calculatorCard_ctaLabel,
        ...$enhancedLinkSerializer(calculatorCardCtaLink),
      },
  };
};

export default ({ primary }, pageData, { $i18n, $enhancedLinkSerializer }) => {
  mergeI18n($i18n, dataI18n);

  const {
    dark_background: darkBackground,
    calculator_card_icon: calculatorCardIcon,
    calculator_card_title: calculatorCardTitle,
    calculator_card_description: calculatorCardDescription,
    calculator_card_cta_label: calculatorCard_ctaLabel,
    calculator_card_cta_link: calculatorCardCtaLink,
    calculator_form_title: calculatorFormTitle,
    calculator_form_intro: calculatorFormIntro,
    calculator_form_footer: calculatorFormFooter,
  } = primary || {};

  return {
    darkBackground,
    calculatorForm: getCalculatorForm(
      { calculatorFormTitle, calculatorFormIntro, calculatorFormFooter },
      $i18n
    ),
    calculatorCard: getCalculatorCard(
      {
        calculatorCardIcon,
        calculatorCardTitle,
        calculatorCardDescription,
        calculatorCard_ctaLabel,
        calculatorCardCtaLink,
      },
      $enhancedLinkSerializer
    ),
  };
};
